import React, { useRef } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput, DateInput,
  required, ImageInput, ImageField,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';
import { useStyles } from "../../formStyles";
import CustomImageDisplay from "../../common/custom-image-display";
import { toast } from "react-toastify";

export const ActivityEdit = (props) => {

  const classes = useStyles({});

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? false : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <Edit {...propsObj} title="Event Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <TextInput source="category" fullWidth={true} validate={[required()]} />
        <RichTextInput source="shortText" fullWidth={true} />
        <RichTextInput toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}
          source="description" fullWidth={true} />
        <RichTextInput source="address" fullWidth={true} />
        <RichTextInput source="contact" fullWidth={true} />
        <RichTextInput source="resources" fullWidth={true} />
        <DateInput source="startDate" label="Start Date" validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} />
        <DateInput source="endDate" label="End Date" validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} />
        <ImageInput source="imageUrl" fullWidth={true}
          label="Logo" accept="image/*" className="image" >
          <ImageField source="imageUrl" title="Image" />
        </ImageInput>
        <CustomImageDisplay field="imageUrl" />
        <BooleanInput initialValue={false} source="isConsentForm" label="Consent Form Required" />
        <RichTextInput source="conscentHeader" label="Consent Header" fullWidth={true} initialValue={""} />
        <RichTextInput source="conscentFooter" label="Consent Footer" fullWidth={true} initialValue={""}/>
        <RichTextInput source="termAndConditions" label="Terms and Conditions" fullWidth={true} initialValue={""}/>
        <RichTextInput source="emailNotificationContents" label="e-mail Notification Content" fullWidth={true} initialValue={""} toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}/>
      </SimpleForm>
    </Edit>
  );
};
