import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="User Name"
      source="userName~like"
      alwaysOn
    />
  </Filter>
);
export const UsersList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="User List"  filters={<Filters />}  >
     <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}

        <TextField source="code" label="User Id" />
        <TextField source="userName" label="User Name " />
        <ReferenceField source="roleTypeId" reference="role-types" label="Role"  link={false}>
                <TextField source="roleName" />
        </ReferenceField>
        <ReferenceField source="approverId" reference="users" label="Approver"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        
        <DateField  source="createdDate" label="Created Date" locales="en-US" />
        <ReferenceField source="modifyBy" reference="users" label="Modified By"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <DateField  source="modifyDate" label="Updated Date" locales="en-US" />
      </Datagrid>
    </List>
  );
};
